<template>
  <div
    class="surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center"
  >
    <div class="surface-card p-3 shadow-4 border-round w-full lg:w-4">
      <div class="text-center mb-0">
        <img
          src="images/blocks/logos/icono.svg"
          alt="Image"
          height="120"
          class="mb-0"
        /><br />
        <span class="text-900 text-3xl font-medium mb-0">¡BIENVENIDOS!</span
        ><br />
        <span class="text-600 font-medium line-height-3">
          Ingrese sus credenciales para acceder al sistema
        </span>
      </div>
      <div class="w-full md:w-12 mx-auto mt-2">
        <Form
          @submit="onSubmit"
          :loading="cargando"
          :validation-schema="schema"
          v-slot="{ errors, isSubmitting }"
        >
          <div class="form-group">
            <span class="block text-900 font-medium">
              <strong>CORREO ELECTRÓNICO:</strong>
            </span>
            <Field
              name="email"
              type="email"
              class="w-full mb-0"
              placeholder="Su Correo Electrónico"
              style="padding: 1rem"
              autocomplete="email"
              :class="{ 'is-invalid': errors.email }"
            />
            <div class="invalid-feedback">{{ errors.email }}</div>
          </div>
          <div class="form-group">
            <span class="block text-900 font-medium mt-2">
              <strong>CONTRASEÑA:</strong>
            </span>
            <Field
              name="password"
              type="password"
              class="w-full mb-0"
              placeholder="Su contraseña"
              style="padding: 1rem"
              autocomplete="current-password"
              :class="{ 'is-invalid': errors.password }"
            />
            <div class="invalid-feedback mb-4">{{ errors.password }}</div>
          </div>
          <div class="flex align-items-center justify-content-between mb-4">
            <Button
              label="INICIAR SESIÓN"
              icon="pi pi-sign-in"
              type="submit"
              class="w-full p-3 text-xl font-bold p-button-info"
              :disabled="isSubmitting"
            />
          </div>
          <div class="flex align-items-center justify-content-between mb-4">
            <h5 v-show="isSubmitting">
              Conectando, Por favor espera...
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            </h5>
          </div>
        </Form>
      </div>
      <div class="text-center mt-2">
        <span class="text-800 font-medium line-height-3">
          <strong>DESARROLLADO POR: </strong>
          <a href="https://sitbolivia.com/" target="_blank">
            <strong>SIT-BOLIVIA</strong>
          </a>
        </span>
        <span class="text-800 font-medium line-height-3">
          | <strong>SOPORTE</strong> : <span class="pi pi-phone"></span>
          <a href="https://wa.me/59163084946">63084946</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { useAuth } from "@/stores";
import { onBeforeMount } from "vue";

const schema = Yup.object().shape({
  email: Yup.string().required("Ingrese Correo").email("Correo Invalido!"),
  password: Yup.string()
    .min(8, "La contraseña debe contener más de 8 caracteres!")
    .required("Ingrese Contraseña!"),
});
let cargando = false;
async function onSubmit(values) {
  const authStore = useAuth();
  await authStore.userLogin(values);
  cargando = true;
}

onBeforeMount(() => {
  if (localStorage.getItem("token")) useRouter().push("/");
});
</script>

<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
.invalid-feedback {
  color: red;
}
</style>